/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
//import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// Material Kit 2 React base styles
import typography from "assets/theme/base/typography";

import Counters from "pages/Presentation/sections/Counters";

// Images
import coverflyLogo from "assets/images/coverfly.png";
import networkisaLogo from "assets/images/network-isa.png";

function Information() {
  const { size } = typography;
  return (
    <MKBox component="section" py={4}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={12}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2" color="warning" textGradient mb={2}>
            Overview
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            Electric Fish is a thrilling and immersive series that follows Max, a young and
            brilliant tech prodigy with a mysterious past, as he embarks on a high-stakes journey
            through the clandestine world of underground hackers, shadowy government agencies, and
            corrupt financial institutions.
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            Inspired by the audacious real-life cyber-attack attempting to steal one billion dollars
            from a country&apos;s central bank, the series delves into the intersection of
            technology and money, where the lines between good and evil blur.
          </MKTypography>
        </Grid>
        <Counters />

        <Divider sx={{ my: 6 }} />
        <Grid
          container
          item
          xs={12}
          lg={12}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h5" color="warning" textGradient mb={2}>
            Project Links
          </MKTypography>
        </Grid>
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={6} md={4} lg={2}>
            <Link
              href="https://writers.coverfly.com/projects/view/9211a31e-3e0d-4e35-8c28-bcb9a9dfa051/Electric_Fish"
              target="_blank"
              rel="noopener"
            >
              <MKBox
                component="img"
                src={coverflyLogo}
                alt="Read on Coverfly"
                width="100%"
                opacity={0.6}
              />
            </Link>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <Link
              href="https://www.networkisa.org/screenwriter-showcase/project_preview/201016"
              target="_blank"
              rel="noopener"
            >
              <MKBox
                component="img"
                src={networkisaLogo}
                alt="Read on ISA Connect"
                width="100%"
                opacity={0.6}
              />
            </Link>
          </Grid>
        </Grid>
        <Grid justifyContent="center">
          <MKTypography
            fontSize={size.xxs}
            fontStyle={"italic"}
            sx={{ mx: "auto", textAlign: "center" }}
          >
            The Coverfly and NetworkISA logos are property of their respective owners.
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
